import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders, HttpClientModule } from '@angular/common/http';
import { ConfigService } from './config.service';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private month = {
    'january': {
      'es': 'Enero',
      'en': 'January'
    },
    'february': {
      'es': 'Febrero',
      'en': 'February'
    },
    'march': {
      'en': 'March',
      'es': 'Marzo'
    },
    'april': {
      'en': 'April',
      'es': 'Abril'
    },
    'may': {
      'en': 'May',
      'es': 'Mayo'
    },
    'june': {
      'en': 'June',
      'es': 'Junio'
    },
    'july': {
      'en': 'July',
      'es': 'Julio'
    },
    'august': {
      'en': 'August',
      'es': 'Agosto'
    },
    'september': {
      'en': 'September',
      'es': 'Septiembre'
    },
    'october': {
      'en': 'October',
      'es': 'Octubre'
    },
    'november': {
      'en': 'November',
      'es': 'Noviembre'
    },
    'december': {
      'en': 'December',
      'es': 'Diciembre'
    },
    'invalidCode': {
      'en': 'Invalid Code',
      'es': 'Codigo Invalido'
    }
  };
  private days = {
    'monday': {
      'es': 'Lunes',
      'en': 'Monday'
    },
    'tuesday': {
      'es': 'Martes',
      'en': 'Tuesday'
    },
    'wednesday': {
      'en': 'Wednesday',
      'es': 'Miércoles'
    },
    'thursday': {
      'en': 'Thursday',
      'es': 'Jueves'
    },
    'friday': {
      'en': 'Friday',
      'es': 'Viernes'
    },
    'saturday': {
      'en': 'Saturday',
      'es': 'Sábado'
    },
    'sunday': {
      'en': 'Sunday',
      'es': 'Domingo'
    },
    'invalidCode': {
      'en': 'Invalid Code',
      'es': 'Codigo Invalido'
    }
  };


  configService: ConfigService;
  constructor(public http: HttpClient) {
    this.configService = new ConfigService();
  }

  getWarehouseDashboard(): Observable<any> {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.get(this.configService.serverIp.concat('/dashboardWareHouse'), { headers });
  }

  getInfoAgency(): Observable<any> {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.get(this.configService.serverIp.concat('/dashboardAgency'), { headers });
  }
  private getMonth(language, monthName) {
    if (!this.month[monthName]) {
      return this.month['invalidCode'][language];
    }
    return this.month[monthName][language];
  }

  showMonth(language: String, monthName: String) {
    // console.log('month: ' + monthName + 'langage: ' + language);
    return this.getMonth(language, monthName);
  }

  private getDays(language, dayName) {
    if (!this.days[dayName]) {
      return this.days['invalidCode'][language];
    }
    return this.days[dayName][language];
  }

  showDays(language: String, dayName: String) {
    return this.getDays(language, dayName);
  }

  getMovemenPackageByUser(userFind?){
    const token = localStorage.getItem('currentUser');
    
    const headers = new HttpHeaders({
      'authorization': token
    });
    let params;
    if(userFind){
       params =  new HttpParams().set('userFind', userFind);
    }
    return this.http.get(this.configService.serverIp.concat('/getMovemenPackageByUser'), { params, headers });
  }

}
